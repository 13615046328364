


export default function ShowTitle({ showTitle, isFullPage, isHeader }) {
    let fadeClass = isHeader ? isFullPage ? 'header-text pre-fade' : 'header-text animate-fade-out' : 'center'; 
    return (
        <>
        <h2 className={fadeClass}>{showTitle}</h2>
        </>
    )
}