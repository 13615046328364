import ShowInfo from "../components/showInfo/ShowInfo"; 
import SubmitInfo from "../components/submissionGuidelines/SubmitInfo"; 
import InfoList from "../components/submissionGuidelines/InfoList";
import Tabs from "../components/tabs/Tabs";
import Footer from "../components/footer/Footer";


export default function TempSubmissionInfo({ isFullPage, showInfo, handleTabClick, viewing }) {
    return (
        <>
        <Tabs isFullPage={isFullPage}   handleTabClick={handleTabClick} viewing={viewing}/>
            <div className="page full-height">
            

            <div className='submission-info'>
                <ShowInfo showInfo={showInfo}/>
                {/* <InfoList/> */}
            </div>
            </div>
            <Footer />
        </>
    )
}