

export default function Footer() {
    return (
        <>
            <div className='footer'>
                <div className="footer-container">
                <div className='footer-section'>
                    <ul>
                    <li>Open Space Arts</li>
                <li>15000 Potomac Town Pl</li>
                <li>Woodbridge, VA 22192</li>

                    </ul>
               
                </div>
                <div className='footer-section'>
                    <ul>
                        <li>Prince William Art Society</li>
                    <li><a href="https://www.princewilliamartsociety.com/" target="_blank">Visit the website</a></li>
                    </ul>
                </div>
                <div className='footer-section'>
                <ul>
                        <li>Prince William County Arts Council</li>
                    <li><a href="https://www.pwcartscouncil.org/" target="_blank">Visit the website</a></li>
                    </ul>
                </div>
                </div>
            </div>
        </>
    )
}