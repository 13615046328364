


export default function Tab({ tab, handleTabClick, viewing, isFullPage }) {
    let useClass = viewing === tab.value ? 'tab-text active' : 'tab-text'; 
    if (!isFullPage) {
        useClass += ' small'; 
    }
    return (
        <>
            <div className='tab' onClick={() => handleTabClick(`${tab.value}`)} value={tab.value}>
                <h4 className={useClass}>{tab.text}</h4>
            </div>
        </>
    )
}