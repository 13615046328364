

export default function ShowDetails({ details }) {
    return (
        <>
            <div class='show-details'>
                <h4>When everything happens</h4>
                <ul>
                    <li>Exhibits: {details.exhibitDateStart} - {details.exhibitDateEnd}</li>
                    <li>Call for art opens: {details.callForArtDateStart}</li>
                    <li>Call for art closes: {details.callForArtDateEnd}</li>
                    <li>Notification of adjudication: {details.notificationDate}</li>
                    <li>Art check-in 1: {details.checkInDateOne}</li>
                    <li>Art check-in 2: {details.checkInDateTwo}</li>
                    <li>Art pick-up: {details.pickUpDate}</li>
                </ul>
            </div>
        </>
    )
}