import SubmitInfo from "../components/submissionGuidelines/SubmitInfo";
import ShowTitle from "../components/showInfo/ShowTitle";
import EntryForm from '../files/EntryForm.docx'; 
import Tabs  from "../components/tabs/Tabs";
import InfoList from "../components/submissionGuidelines/InfoList";
import Footer from "../components/footer/Footer";

export default function HowToSubmit({ isFullPage, showTitle, handleTabClick, viewing }) {
    return (
        <>
        <Tabs isFullPage={isFullPage} handleTabClick={handleTabClick} viewing={viewing} />

        <div className="page full-height">
        
        <div className='submission-info'>
        <ShowTitle showTitle={showTitle} />
        <InfoList/>
        <SubmitInfo />
        <div className='button-container'>
        <a className="button download" href={EntryForm} download>
            {/* <div className=""> */}
                Download Form
            {/* </div> */}
            </a>
        </div>
        </div>
        </div>
        <Footer />
        </>
    )
}