import Tab from "./Tab"

const tabs = [{id: 1, text: 'Exhibition Info', value: 'info'}, {id: 2, text: 'How to Submit', value: 'howtosubmit'}, {id: 3, text: 'About Us', value: 'about'}]
export default function Tabs({ isFullPage, handleTabClick, viewing }) {
    let useClass = isFullPage ? "temp-nav tabs" : "temp-nav tabs small"
    return (
        <>
            <div className={useClass}>
                {tabs.map((tab) => {
                    return (
                        <Tab key={tab.id} tab={tab} handleTabClick={handleTabClick} viewing={viewing} isFullPage={isFullPage}/>
                    )
                })}
            </div>
        </>
    )
}