import ShowInfo from "../components/showInfo/ShowInfo"; 
import SubmitInfo from "../components/submissionGuidelines/SubmitInfo"; 
import InfoList from "../components/submissionGuidelines/InfoList";
import Tabs from "../components/tabs/Tabs";
import Footer from "../components/footer/Footer";



export default function About({ isFullPage, showInfo, handleTabClick, viewing }) {
    return (
        <>
         <Tabs isFullPage={isFullPage}   handleTabClick={handleTabClick} viewing={viewing}/>
            <div className="page full-height">
           

            <div className='submission-info'>
                <h2 className="center">About us</h2>
                <h4>What is Open Space Arts? </h4>
                <p>Open Space Arts at Stone Bridge is a  premiere open space  multi-discipline arts facility that plays a significant role in promoting the value of art appreciation through classes, workshops, exhibitions, performances, cultural and specialty programs. Stone Bridge and JBG Smith, the management company, supports its commitment to serving the public by offering an opportunity for local PWC artists to present creative performance and visual art works of various mediums in their vacant space.
            </p>
            <p>One of the ways OSA supports these local artists is by hosting visual art exhibits in its gallery area. These exhibits last between two and six weeks and are usually centered around a particular topic that encourages discourse, highlights the diversity of culture in the county, or inspires us to seek beauty. </p>
            <p>OSA would not be able to exist today without the support, contributions, and expertise of the Prince William County Arts Council and the Prince William Art Society. </p>
            <p>If you have any questions, please reach out to <a href='mailto:osasubmissions@mail.com'>osasubmissions@gmail.com</a>. Thank you for visiting and we hope to see a submission from you!</p>
                {/* <InfoList/> */}
            </div>
            </div>
            <Footer />
            
        </>
    )
}