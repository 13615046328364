import EntryForm from '../../files/EntryForm.docx'; 

export default function SubmitInfo() {
    return (
        <>
            <div class='submit-info'>
                <h4>How to Submit</h4>
                <ul>
                    {/* <li>Download and complete the <a href={EntryForm} download>entry form</a></li> */}
                    <li  className="bullet">Download and complete the entry form below</li>
                    <li className="bullet">Prepare images of artwork for the jury</li>
                    <ul>
                        <li className='nested-list'>Submitted images should be the best possible quality - crop out backgrounds, center the artwork in the photo, and ensure all detail is captured</li>
                        <li className='nested-list'>The entry form contains more information about file naming conventions, sales commission taken by the gallery, category limits, expected hanging hardware, etc. Ensure that you understand, agree to, and can fulfill all requirements outlined </li>
                    </ul>
                    
                    <li className="bullet">Attach the completed entry form and photos of artwork to an email addressed to <a href='mailto:osasubmissions@gmail.com'>osasubmissions@gmail.com</a></li>
                </ul>
            </div>
        </>
    )
}