

export default function InfoList() {
    return (
        <>
            <div className="submit-info">
                <h4>Things to know</h4>
                <ul>
                    <li className="bullet">All work exhibited at OSA during this show will be for sale.</li>
                    <li className="bullet">All artists who have work juried into the exhibition must volunteer to work x shifts at the gallery during the show.</li>
                    <li className="bullet">Volunteering requires training. Trainings take place on the second Saturday of each month from 10:00-11:30am in the gallery space at OSA.</li>
                </ul>
            </div>   
        </>
    )
}