import ShowTitle from "./ShowTitle"; 
import ShowDetails from "./ShowDetails";
import ShowDescription from "./ShowDescription";

export default function ShowInfo({ showInfo }) {
    return (
        <>
            <div className='show-info'>
                {/* <h3 className='underline'>Currently accepting submissions for</h3> */}
                <ShowTitle showTitle={showInfo.showTitle} />
                <ShowDescription description={showInfo.description} />
                <ShowDetails details={showInfo.details} />
            </div>
        </>
    )

}