import ShowTitle from "../showInfo/ShowTitle"; 
import ShowDescription from "../showInfo/ShowDescription";
import Tabs from "../tabs/Tabs";

export default function Header({ isFullPage, showTitle, description, handleTabClick, viewing }) {
    let useClass = isFullPage ? 'app-header full-height' : 'app-header animate-header-shrink'; 
    let fadeClass = isFullPage ? 'header-text pre-fade' : 'header-text animate-fade-out'; 
    return (
        <header className={useClass} >
            <h1>Open Space Arts</h1>
            <div className={fadeClass}>
            <ShowTitle showTitle={showTitle} />
            <h3 className=''>call for art</h3>
            
            <Tabs isFullPage={isFullPage}   handleTabClick={handleTabClick} viewing={viewing}/>
            </div>
            
            {/* <ShowTitle showTitle={showTitle} isFullPage={isFullPage} isHeader={true}/>
            {isFullPage ? <h3 className='header-text pre-fade'>call for art</h3> : <h3 className='header-text animate-fade-out'>call for art</h3>}
            <Tabs handleTabClick={handleTabClick}/> */}
        {/* <ShowDescription description={description}/> */}
      </header>
    )
}