import { useRef, useState } from 'react'; 
import useScrollSnap from 'react-use-scroll-snap';
import logo from './logo.svg';
import './App.css';
import SubmissionFormSection from './components/submission/SubmissionFormSection';
import TempSubmissionInfo from './pages/TempSubmissionInfo';
import ShowTitle from "./components/showInfo/ShowTitle";
import ShowDescription from "./components/showInfo/ShowDescription";
import ShowDetails from "./components/showInfo/ShowDetails";
import Header from './components/header/Header';
import HowToSubmit from './pages/HowToSubmit';
import About from './pages/About';
import Footer from './components/footer/Footer';


const showInfo = {
  showTitle: 'Pride at OSA',  
  details: {
      exhibitDateStart: 'June 21 2023', 
      exhibitDateEnd: 'July 23 2023', 
      callForArtDateStart: 'May 15 2023', 
      callForArtDateEnd: 'May 31 2023', 
      notificationDate: 'June 3 2023', 
      checkInDateOne: 'June 18 2023 (5-6pm)', 
      checkInDateTwo: 'June 19 2023 (12-2pm)', 
      pickUpDate: 'July 23 (5-6pm) and July 24 (12-2pm)', 
  }, 
  description: 'Using the elements of art (colors, lines, shapes, value, space, and texture) we are looking for work that highlights expressions of acceptance, explores the diversity of relationships and family types, celebrates the rich and varied sub-cultures of LGBTQ+ Americans, and/ or discusses the ongoing fight for Transgender rights in America.', 
}
function App() {
  const [viewing, setViewing] = useState(null); 
  const scrollRef= useRef(null); 
  const [isFullPage, setFullPage] =  useState(true); 

  const handleTabClick = (value) => {
    setViewing(value); 
    setFullPage(false); 
  }
  return (
    <div className="App" ref={scrollRef}>
      <Header isFullPage={isFullPage}  showTitle={showInfo.showTitle} description={showInfo.description} handleTabClick={handleTabClick} viewing={viewing}/>
      {viewing && viewing === 'info' && <TempSubmissionInfo isFullPage={isFullPage} showInfo={showInfo}  handleTabClick={handleTabClick} viewing={viewing}/>}
      {viewing && viewing === 'howtosubmit' && <HowToSubmit isFullPage={isFullPage}  showTitle={showInfo.showTitle}  handleTabClick={handleTabClick} viewing={viewing}/>}
      {viewing && viewing === 'about' && <About isFullPage={isFullPage}  showTitle={showInfo.showTitle}  handleTabClick={handleTabClick} viewing={viewing}/>}
      {/* <Footer />S */}
    </div>
  );
}

export default App;
