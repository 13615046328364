


export default function ShowDescription({ description }) {
    return (
        <>
            <h4>What it is</h4>
            <p>{description}</p>
            <p>Selections are open to interpretation by the artist. Please submit quality work that has not been juried or displayed during the past three shows at Open Space Arts Gallery.</p>
        </>
    )
}